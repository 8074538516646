
import { Component, Vue } from "vue-property-decorator";
import { Order } from "@/models/entities/order.interface";
import { OrderStatus } from "@/models/utils/order-status.enum";
import { orders } from "@/services/api/orders.service";
import ResUserOrder from "./ResUserOrder.vue";

@Component({
  components: { ResUserOrder },
})
export default class ResMyOrders extends Vue {
  private orders: Order[] = [];
  private range: string[] = null;

  private get rangeCheck(): boolean {
    return this.range && this.range.length === 2;
  }

  private created(): void {
    this.getData();
  }

  private async getData(): Promise<void> {
    await this.getOrders();
  }

  private async getOrders(): Promise<void> {
    const from = this.rangeCheck ? this.range[0].toDate() : null;
    const to = this.rangeCheck ? this.range[1].toDate() : null;
    const o = await orders.getAllOrdersCurrentClient(
      from,
      to,
      OrderStatus.NONE
    );
    o.data.forEach((order) => {
      order.formattedConfirmationDate = order.confirmationDate
        .toDate()
        .toFormat("dd MMMM yyyy");
    });
    this.orders = o.data;
  }

  private navHome(): void {
    this.$router.push({ name: "home" });
  }
}
