
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/models/entities/order.interface";
import { OrderProduct } from "@/models/entities/order-product.interface";
import { orders } from "@/services/api/orders.service";
import { productPhoto } from "@/services/api/productPhoto.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import CartModule from "@/store/modules/cart.module";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);
const cartModule = getModule(CartModule, store);

@Component
export default class ResUserOrder extends Vue {
  /** Sets the order of the user */
  @Prop({ required: true })
  private order: Order;

  private showProductsDialog = false;
  private photoLoading = false;
  private products: OrderProduct[] = [];
  private photosDict = {} as { [id: number]: string };
  private rejectDialog = {
    show: false,
    description: "",
    title: "",
    actions: [],
  };
  private orderAgainDialog = {
    show: false,
    description:
      "Vuoi effettuare di nuovo questo ordine? I prodotti verranno aggiunti al tuo carrello. Da lì potrai verificare le disponibilità e i prezzi aggiornati.",
    title: "Ordina di nuovo",
    actions: [
      {
        text: "Annulla",
        type: "neutral",
        action: () => this.closeOrderAgainDialog(),
      },
      {
        text: "Aggiungi al carrello",
        type: "primary-color",
        action: () => this.orderAgain(),
      },
    ],
  };

  private get tagText(): string {
    if (this.order.isCanceledByAdmin) return "Respinto";
    if (this.order.isDefinitevelyConfirmed) return "Confermato";
    if (this.order.isCanceled) return "Annullato";
    return "In attesa";
  }

  private get tagType(): string {
    if (this.order.isCanceledByAdmin || this.order.isCanceled) return "error";
    if (this.order.isDefinitevelyConfirmed) return "success";
    return "neutral";
  }

  private get resFormattedConfirmationDate(): string {
    return this.order.confirmationDate.toDate().toFormat("dd/MM/yyyy");
  }

  private async getOrderProducts(): Promise<void> {
    overlayModule.showOverlay();
    const p = await orders.getOrderProductsHistoryByOrderID(this.order.id);
    this.products = p.data;
    this.photo();
  }

  private async photo(): Promise<void> {
    this.photoLoading = true;
    let measurePromises = [];
    let defaultPromises = [];
    for (const product of this.products) {
      measurePromises.push(
        productPhoto.getPhotoByMeasureUnitTypeID(
          product.productPriceBookID,
          product.measureUnitTypeID
        )
      );
    }
    const measureResponses = await Promise.all(measurePromises);

    for (let idx = 0; idx < this.products.length; idx++) {
      if (measureResponses[idx].data.byteLength == 0)
        defaultPromises.push(
          productPhoto.getDefaultPhotoByProductId(this.products[idx].productID)
        );
      else defaultPromises.push(null);
    }
    const defaultResponses = await Promise.all(defaultPromises);

    for (let idx = 0; idx < measureResponses.length; idx++) {
      let arrayBuffer = defaultResponses[idx]
        ? defaultResponses[idx].data
        : null;
      if (measureResponses[idx].data.byteLength != 0)
        arrayBuffer = measureResponses[idx].data;
      let img = window.URL.createObjectURL(
        new Blob([new File([arrayBuffer], "photo")], { type: "image/png" })
      );
      this.$set(this.photosDict, this.products[idx].productID, img);
    }
    this.photoLoading = false;
  }

  private openOrderAgain(): void {
    this.orderAgainDialog.show = true;
  }

  private async orderAgain(): Promise<void> {
    await this.getOrderProducts();
    for (const product of this.products) {
      try {
        overlayModule.showOverlay();
        await orders.addOrder({
          productPriceBookID: product.productPriceBookID,
          quantity: product.quantity,
          measureUnitTypeID: product.measureUnitTypeID,
        });
      } catch {
        continue;
      }
    }
    const r = await orders.getCart();
    cartModule.getCart(r.data);
    this.closeOrderAgainDialog();
  }

  private closeOrderAgainDialog(): void {
    this.orderAgainDialog.show = false;
  }

  private rejectOrder(): void {
    this.rejectDialog.title = "Contattaci";
    this.rejectDialog.actions = [
      {
        text: "Ok",
        type: "primary-color",
        action: () => this.closeRejectDialog(),
      },
    ];
    if (this.order.isDefinitevelyConfirmed) {
      this.rejectDialog.description =
        "L'ordine è già stato confermato. Contatta il nostro servizio clienti per maggiori informazioni.";
    } else if (this.order.isCanceledByAdmin) {
      this.rejectDialog.description =
        "L'ordine è già stato respinto da un amministratore. Contatta il nostro servizio clienti per maggiori informazioni.";
    } else if (this.order.isCanceled) {
      this.rejectDialog.description =
        "L'ordine è già stato annullato. Contatta il nostro servizio clienti per maggiori informazioni.";
    } else if (this.order.isConfirmed) {
      this.rejectDialog.title = "Annulla ordine";
      this.rejectDialog.description = "Sei sicuro di voler annullare l'ordine?";
      this.rejectDialog.actions = [
        {
          text: "Mantieni ordine",
          type: "neutral",
          action: () => this.closeRejectDialog(),
        },
        {
          text: "Annulla ordine",
          type: "primary-color",
          action: () => this.rejectConfirm(),
        },
      ];
    }
    this.rejectDialog.show = true;
  }

  private closeRejectDialog(): void {
    this.rejectDialog.show = false;
  }

  private async rejectConfirm(): Promise<void> {
    overlayModule.showOverlay();
    await orders.rejectOrder(this.order.id);
    this.$emit("reload");
    this.rejectDialog.show = false;
    snackbarModule.showSnackbar({
      message: "Ordine annullato con successo",
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 2000);
  }

  private async openProductsDialog(): Promise<void> {
    await this.getOrderProducts();
    this.showProductsDialog = true;
  }

  private closeProductsDialog(): void {
    this.showProductsDialog = false;
  }
}
